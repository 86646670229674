
	
<template>
<div class="grid">
	<div class="col-12 md:col-12">
		<div class="text-2xl font-bold my-4">Change Password</div>
		<form ref="observer"  tag="form" @submit.prevent="startSaveRecord()">
			<div class="field">
				<Password class="w-full"  inputClass="w-full" :feedback="false" :toggleMask="true" label="Current Password"  placeholder="Current Password"  v-model="formData.oldpassword"  />
			</div>
			<div class="field">
				<Password class="w-full"  inputClass="w-full" :toggleMask="true" label="New Password"  placeholder="New Password"  v-model="formData.newpassword" 	/>
			</div>
			<div class="field">
				<Password class="w-full" inputClass="w-full" :feedback="false" :toggleMask="true" label="Confirm new password"  placeholder="Confirm new password" v-model="formData.confirmpassword"  />
			</div>
			<div class="my-3 text-center">
				<Button type="submit" icon="pi pi-send" label="Change Password" :loading="saving" />
			</div>
		</form>
	</div>
</div>

</template>
<script>
import { PageMixin } from "../../mixins/page.js";
import useVuelidate from '@vuelidate/core';
import { required, sameAs } from '@vuelidate/validators';

export default {
	setup () {
		return { v$: useVuelidate() }
	},
	props:{
		apiUrl: {
			type: String,
			default: 'account/changepassword'
		},
	},
	mixins: [PageMixin],
	data() {
		return {
			saving: false,
			formData: {
				oldpassword: '', 
				newpassword: '', 
				confirmpassword: '', 
			},
		}
	},
	validations() {
			let formData =  {
				oldpassword: { required },
				newpassword: { required },
				confirmpassword: { 
					required, 
					sameAsPassword: sameAs(this.formData.newpassword),
				}
			}
			return {formData}
		},
	methods: {
		async startSaveRecord(){
			const isFormValid = !this.v$.$invalid;
			if(!isFormValid){
				this.flashMsg("Please complete the form", "Form is invalid", "error");
				return;
			}
			this.saving = true;
			let payload = this.formData;
			let url = this.apiUrl;
			this.$api.post(url, payload).then((response) => {
				this.saving = false;
				this.resetForm();
				this.flashMsg("Password change completed");
			},(response) => {
				this.saving = false;
				this.showPageRequestError(response);
			});
			
		},
		resetForm (){
			this.formData = {oldpassword: "", newpassword: "", confirmpassword: "", };
			requestAnimationFrame(() => {
				this.$refs.observer.reset();
			});
		},
	},
}
</script>
